import { useEffect, useState } from 'react';

export function Shielded() {
  const [done, setDone] = useState<boolean>(false);

  useEffect(() => {
    if (done) return;
    (function () {
      window.onload = function () {
        const shielded = (window as any).ds07o6pcmkorn;
        const frameName = new shielded({
          openElementId: '#shielded-logo',
          modalID: 'modal',
        });
        frameName.init();
      };
    })();
    setDone(true);
  }, [done]);

  return (
    <div className="cursor-pointer" id="shielded-logo">
      <img
        alt="shielded"
        className="size-8"
        src="https://shielded.co.nz/img/custom-logo.png"
      />
    </div>
  );
}
