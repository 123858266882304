import Link from 'next/link';

/*--------------------------------------------------------------------*/

/**
 * Component Props
 */
type LinkItemProps = {
  href: string;
  children: React.ReactNode;
};

/**
 * Component
 */
const LinkItem = ({ href, children }: LinkItemProps) => (
  <li className="mb-2 text-base font-medium leading-[22px] text-orange-50 md:text-lg md:leading-[25px]">
    <Link href={href}>{children}</Link>
  </li>
);

export default LinkItem;
