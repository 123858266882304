// Components
import Image from 'next/image';
import { EnvelopeIcon } from '@heroicons/react/24/outline';
import { Logo } from 'components/Logo';
import { Facebook, Instagram, Linkedin } from 'components/icons';
import LinkItem from 'components/Footer/HomeContact/LinkItem';
import { useAuthContext } from 'components/AuthContext';
import { EmployerText } from '../../selfService/typography/EmployerText';
import { HomeContactForm } from './HomeContactForm';
import { Shielded } from './Shielded';

/*--------------------------------------------------------------------*/

/**
 * Component
 */

export function HomeContact(): JSX.Element {
  const { user } = useAuthContext();

  return (
    <footer
      id="__contact_form_container"
      className="bg-charcoal-500 px-4 py-24 text-white md:px-16"
    >
      <div className="mx-auto max-w-3xl font-satoshi lg:max-w-4xl xl:max-w-5xl 2xl:max-w-7xl">
        <div className="mb-32 w-full">
          <Logo />
        </div>
        <div className="mx-auto flex flex-col gap-12 lg:flex-row xl:gap-32">
          <div className="order-2 flex w-full flex-col items-center lg:order-1 lg:block lg:w-1/2">
            <div className="grid w-full max-w-[425px] grid-cols-2 gap-x-4 gap-y-14 text-lg lg:grid-cols-3">
              <div>
                <EmployerText size="xl" as="p" className="mb-6 text-orange">
                  Explore
                </EmployerText>
                <ul>
                  <LinkItem href="/jobs">Jobs</LinkItem>
                  <LinkItem href="/directory">Business directory</LinkItem>
                  <LinkItem href="/training">Training</LinkItem>
                  <LinkItem href="/about">About</LinkItem>
                </ul>
              </div>
              <div className="pl-3 sm:pl-0">
                <EmployerText size="xl" as="p" className="mb-6 text-orange">
                  Account
                </EmployerText>
                <ul>
                  <LinkItem href={user ? '/portal' : '#login'}>Login</LinkItem>
                  <LinkItem href="/register">Signup</LinkItem>
                </ul>
              </div>
              <div>
                <EmployerText size="xl" as="p" className="mb-6 text-orange">
                  Employers
                </EmployerText>
                <ul>
                  <LinkItem href="/portal">Employer site</LinkItem>
                  <LinkItem href="/portal/register">Post a Job</LinkItem>
                </ul>
              </div>
              <div className="col-span-3 grid grid-cols-2 gap-4">
                <div>
                  <EmployerText size="xl" as="p" className="mb-6 text-orange">
                    Contact us
                  </EmployerText>
                  <a
                    href="https://www.google.com/maps/place/B:HIVE/@-36.7845203,174.7520736,17z/data=!3m1!4b1!4m5!3m4!1s0x6d0d39c80a9cd8f9:0xe503dfde6c122f02!8m2!3d-36.7845203!4d174.7542623"
                    className="text-base font-medium leading-[22px] text-orange-50 md:text-lg md:leading-[25px]"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p>B:Hive SmalesFarm.</p>
                    <p>72 Taharoto Road, Takapuna</p>
                  </a>
                </div>
              </div>
            </div>
            <div className="mt-6 flex h-8 justify-start gap-4">
              <a
                href="https://www.facebook.com/tradejobsnz/"
                target="_blank"
                rel="noreferrer"
              >
                <Facebook className="w-8" fill="#F9F8F5" />
              </a>
              <a
                href="https://www.instagram.com/tradejobsnz/"
                target="_blank"
                rel="noreferrer"
              >
                <Instagram className="w-8" fill="#F9F8F5" />
              </a>
              <a
                href="https://www.linkedin.com/company/trade-jobs-nz"
                target="_blank"
                rel="noreferrer"
              >
                <Linkedin className="w-8" fill="#F9F8F5" />
              </a>
              {/** there is a little JS script that handles this guy */}
              <Shielded />
            </div>
            <div className="mt-6 h-10 max-w-content">
              <a
                href="https://amotai.nz/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  className="h-full w-auto"
                  width={232}
                  height={72}
                  alt="Amotai Registered Partner"
                  src="/assets/images/partners/Amotai-LS.png"
                />
              </a>
            </div>
          </div>
          <div className="order-1 flex w-full flex-col items-center lg:order-2 lg:w-1/2">
            <HomeContactForm />
          </div>
        </div>
      </div>
    </footer>
  );
}
