import { PropsWithChildren } from 'react';

import { twMerge } from 'tailwind-merge';

type Props = PropsWithChildren<{
  className?: string;
  opacity?: number;
}>;

export const BackgroundGrid = ({
  children,
  className = '',
  opacity = 90,
}: Props): JSX.Element => (
  <section
    className={twMerge('relative z-10 bg-cream-500 font-satoshi', className)}
  >
    <div className="z-10 mx-auto mt-16 w-full max-w-6xl px-4">{children}</div>
    <div
      className={twMerge('absolute inset-0 -z-10 bg-cream bg-repeat')}
      style={{
        backgroundSize: '400px',
        backgroundImage: `url("/assets/bg/orange.svg")`,
        opacity: opacity / 100,
      }}
    ></div>
    <div className="absolute inset-0 -z-10 bg-gradient-to-r from-transparent via-cream-500 via-50% opacity-90"></div>
  </section>
);
