import * as yup from 'yup';
import axios from 'axios';

export const schema = yup.object({
  name: yup.string().required('name is required'),
  email: yup
    .string()
    .email('must be a valid email')
    .required('email is required'),
  category: yup
    .array()
    .of(yup.string())
    .required('Category Selection is Required'),
});

export interface ContactFormValues {
  name: string;
  email: string;
  category: string[];
}

export async function onSubmit(values: ContactFormValues) {
  return axios.post(`/api/contact`, values);
}
