// Framework
import dynamic from 'next/dynamic';

// Third Parties
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';

// Components
import { UpdatedInput } from 'components/forms/UpdatedFormInput';
import { EmployerText } from 'components/selfService/typography/EmployerText';
import { useMessages } from 'components/Messages';
import { useGetEnumCategoriesQuery } from 'database/types';
import { EnumType } from 'types/utils';
const UpdatedMultiSelect = dynamic(
  () => import('components/forms/newDesign/UpdatedMultiSelect')
);

// Helpers
import HubspotSubmit from 'utils/HubspotSubmit';
import { ContactFormValues, schema } from './onSubmit';

/*--------------------------------------------------------------------*/

/**
 * Component
 */

export function HomeContactForm() {
  const { data: catData, loading: cLoading } = useGetEnumCategoriesQuery();
  const { alert } = useMessages();
  const { register, handleSubmit, control } = useForm<ContactFormValues>({
    defaultValues: {
      name: '',
      email: '',
      category: [],
    },
    resolver: yupResolver(schema) as any,
  });

  const onSubmit = async (data: any) => {
    try {
      const portalId = '20147764';
      const formGuid = '5fd91072-791e-4ec1-926c-3a9b73c5b5a2';
      const fields = [
        {
          name: 'firstname',
          value: data.name,
        },
        {
          name: 'email',
          value: data.email,
        },
        {
          name: 'trade_category',
          value: data.category?.join('; '),
        },
      ];

      await HubspotSubmit({ portalId, formGuid, fields });

      alert({
        key: 'NewsLetter',
        type: 'success',
        title: 'You are subscribed',
        dismissable: true,
        duration: 3000,
      });
    } catch (error: any) {
      alert({
        key: 'NewsLetter',
        type: 'error',
        title: error.message,
        dismissable: true,
      });
      // captureException(error);
    }
  };

  return (
    <form
      id="__contact_employer"
      onSubmit={handleSubmit(onSubmit)}
      className="max-w-[427px] font-satoshi"
    >
      <EmployerText as="p" size="lg" className="mb-6 text-2xl text-orange-50">
        Get weekly updates with jobs listed on TradeJobs NZ
      </EmployerText>
      <div className="mb-6 h-px w-full bg-charcoal-400" />
      <UpdatedInput
        {...register('name')}
        label="Your Name*"
        placeholder="Name"
        required
        customLabel="text-white font-bold text-sm leading-5 mb-1 text-left"
      />
      <UpdatedInput
        label="Email Address*"
        placeholder="Email address"
        required
        customLabel="text-white font-bold text-sm leading-5 mb-1 text-left"
        {...register('email')}
      />
      {!cLoading && (
        <Controller
          control={control}
          name="category"
          render={({ field }) => (
            <UpdatedMultiSelect
              value={field.value}
              options={(catData?.enum_category as EnumType[]) ?? []}
              defaultValues={[]}
              label="Add category*"
              name="category"
              onChange={field.onChange}
              className="mx-auto flex w-full flex-col justify-start text-left"
              customLabel="text-white font-bold text-sm leading-5 mb-1 text-left"
            />
          )}
        />
      )}
      <button
        type="submit"
        className="h-12 w-full rounded-lg border border-solid border-orange-500 bg-orange"
      >
        <p className="font-medium leading-6 text-white">Sign Up</p>
      </button>
    </form>
  );
}
